// import Elevio from 'elevio/lib/react'
// import './App.css'
import Header from './pages/Header'

function App() {  
  return (
    <div className="App">
      <Header />
      {/* <Elevio accountId="624fc773a0a8f" />
      <div className='container'>
        <elevio-element data-type="article" data-id="66" />
      </div> */}
    </div>
  );
}
// export const App = () => {
  
// }
export default App;
