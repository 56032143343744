import styled from 'styled-components'
import AppendScript from '../external-components/appendScript'

const HeaderContainer = styled.header`
height: 64px;
background: #111;
color: white;
display: flex;
align-items: center;
justify-content: center;
.cursor-pointer {
	cursor: pointer;
}
`

const Header = () => {
	return <HeaderContainer>
		<span className='cursor-pointer'>Header goes here <AppendScript /></span>
	</HeaderContainer>
}

export default Header